define(['lodash',
    'prop-types',
    'componentsCore',
    'warmupUtils',
    'image-client-api',
    'santa-components'],
function (_,
          PropTypes,
          componentsCore,
          warmupUtilsLib,
          imageClientLib,
          santaComponents) {
    'use strict';
    const imageElements = santaComponents.components.imageCommon.imageElements;

    /**
     * @class components.bgImage
     * @extends {core.skinBasedComp}
     */
    return {
        displayName: 'bgImage',
        mixins: [componentsCore.mixins.skinBasedComp],
        propTypes: {
            cssFiltersSupported: santaComponents.santaTypesDefinitions.BrowserFlags.cssFiltersSupported.isRequired,
            compData: PropTypes.object.isRequired,
            shouldRenderSrc: PropTypes.bool,
            imageUrlPreMeasureParams: PropTypes.object,
            'data-type': PropTypes.string.isRequired,
            currentUrl: santaComponents.santaTypesDefinitions.currentUrl,
            getMediaFullStaticUrl: santaComponents.santaTypesDefinitions.ServiceTopology.getMediaFullStaticUrl.isRequired,
            filterEffect: PropTypes.object
        },

        extendWithFilterData(refData) {
            let filterId, filterComp;
            const validFilterName = imageElements.getValidFilterName(this.props.filterEffect);

            if (validFilterName) {
                filterId = `${this.props.id}_${validFilterName}`;
                filterComp = imageElements.getFilterComponent(filterId, validFilterName);

                if (this.props.cssFiltersSupported) {
                    refData[''].addChildren = filterComp;
                    _.assign(refData.image.style, imageElements.getCssStyleForFilterUse(filterId));
                }
            }
        },

        getImageTransform() {
            const {imageData, getMediaFullStaticUrl, currentUrl, style} = this.props;
            const imageUrlPreMeasureParams = _.pick(this.props.imageUrlPreMeasureParams, [
                'width', 'height', 'filters', 'fittingType', 'imageCss'
            ]);

            const imageInfo = {
                imageData,
                containerWidth: _.defaultTo(imageUrlPreMeasureParams.width, style.width),
                containerHeight: _.defaultTo(imageUrlPreMeasureParams.height, style.height),
                displayMode: _.defaultTo(imageUrlPreMeasureParams.fittingType, imageClientLib.fittingTypes.TILE),
                quality: {quality: 85},
                filters: imageUrlPreMeasureParams.filters
            };
            //const imageTransform = warmupUtilsLib.imageUtils.getImageComputedProperties(imageClientLib, imageInfo, getMediaFullStaticUrl, currentUrl, 1, 'bg');

            return warmupUtilsLib.imageUtils.getImageComputedProperties(imageClientLib, imageInfo, getMediaFullStaticUrl, currentUrl, 1, 'bg');
        },

        getSkinProperties() {
            const outerStyle = {
                width: '100%'
            };

            const innerStyle = {
                position: 'absolute',
                width: '100%'
            };

            if (_.isNumber(this.props.compData.opacity)) {
                innerStyle.opacity = this.props.compData.opacity;
            }

            if (this.props.shouldRenderSrc) {
                const imageTransformData = this.getImageTransform();
                innerStyle.backgroundImage = `url(${imageTransformData.uri})`;
                innerStyle.height = this.props.style.height;
                _.assign(innerStyle, imageTransformData.css.container);
            }

            const refData = {
                '': {
                    'style': outerStyle
                },
                'image': {
                    'style': innerStyle,
                    'data-type': this.props['data-type']
                }
            };

            this.extendWithFilterData(refData);

            return refData;
        }
    };
});
