define([
    'lodash',
    'prop-types',
    'componentsCore',
    'coreUtils',
    'backgroundCommon/components/videogl'
], function (
    _,
    PropTypes,
    componentsCore,
    coreUtils,
    vgl) {
    'use strict';

    const consts = coreUtils.mediaConsts;

    function getWebglDimensions(props) {
        let {width = props.mediaWidth, height = props.mediaHeight} = props.playerStyle || {};
        const scale = Math.min(1, Math.max(width / props.mediaWidth, height / props.mediaHeight));

        width = Math.round(props.mediaWidth * scale);
        height = Math.round(props.mediaHeight * scale);

        return {width, height};
    }

    return {
        displayName: 'webglMedia',
        mixins: [componentsCore.mixins.skinBasedComp],
        propTypes: {
            playerStyle: PropTypes.object,
            notifyMediaState: PropTypes.func,
            getMediaSource: PropTypes.func,
            mediaWidth: PropTypes.number,
            mediaHeight: PropTypes.number,
            showMedia: PropTypes.bool
        },

        getDefaultProps() {
            return {
                playerStyle: {}
            };
        },
        getInitialState() {
            this.vgl = null;
            return {};
        },

        componentDidMount() {
            const target = this.refs.canvas;
            const effects = [vgl.transparentVideo()];

            try {
                this.vgl = new vgl.Vgl({target, effects});
            } catch (e) {
                this.props.notifyMediaState({
                    type: consts.eventTypes.ERROR,
                    error: consts.errorTypes.WEBGL_ERROR
                });
            }
        },

        componentWillReceiveProps(nextProps) {
            const media = nextProps.getMediaSource();

            if (media) {
                const {width, height} = getWebglDimensions(nextProps);
                const source = {
                    type: 'video',
                    media,
                    width,
                    height
                };
                if (this.vgl) {
                    this.vgl.data[0].uniforms[0].data[1] = height;
                    this.vgl.setSource(source);
                }
            }
        },

        componentWillUnmount() {
            if (this.vgl) {
                this.vgl.destroy();
                this.vgl = null;
            }
        },

        getSkinProperties() {
            return {
                '': {
                    style: {
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        overflow: 'hidden'
                    }
                },
                canvas: {
                    style: {
                        position: 'absolute',
                        opacity: this.props.showMedia ? 1 : 0
                    }
                }
            };
        }
    };
});
