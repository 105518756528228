/* eslint-disable santa/no-side-effects */
define(['lodash', 'prop-types', 'componentsCore', 'coreUtils', 'santa-components', 'backgroundCommon/mixins/videoPlayerMixin'], function (_, PropTypes, componentsCore, coreUtils, santaComponents, videoPlayerMixin) {
    'use strict';

    const consts = coreUtils.mediaConsts;

    function isVideoPartRendered(parts) {
        return _.includes(parts, 'video');
    }

    /**
     * @class components.html5Video
     * @extends {core.skinBasedComp}
     */
    return {
        displayName: 'html5Video',
        mixins: [videoPlayerMixin, componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin],
        propTypes: _.defaults(
            {
                compData: PropTypes.object.isRequired,
                compProp: PropTypes.object,
                playFullscreen: PropTypes.bool,
                videoRenderParts: PropTypes.array,
                format: PropTypes.string,
                config: PropTypes.object,
                playbackUrl: PropTypes.string,
                mediaQuality: PropTypes.string,
                notifyMediaState: PropTypes.func.isRequired,
                notifyVideoVisibility: PropTypes.func.isRequired,
                showMedia: PropTypes.bool.isRequired,
                shouldRenderSrc: PropTypes.bool,
                posterWidth: PropTypes.number,
                posterHeight: PropTypes.number,
                setMediaAPI: PropTypes.func.isRequired,
                registerMediaSource: PropTypes.func,
                keepVideoHidden: PropTypes.bool
            },
            santaComponents.utils.santaTypesUtils.getSantaTypesFromPropTypes(santaComponents.components.Image.propTypes)
        ),

        // Lifecycle

        getInitialState() {
            this.playWhenReady = false;
            this.hls = null;
        },

        componentDidMount() {
            //register to timeupdate in order to remove the poster
            this.refs.video.addEventListener('timeupdate', this.handlePosterVisibilityOnce);
            //handle volume and mute
            this.refs.video.addEventListener('volumechange', this.onVolumeChange);

            this.props.notifyMediaState({
                type: consts.eventTypes.MOUNT,
                playbackState: consts.playbackTypes.LOADING
            });
            if (isVideoPartRendered(this.props.videoRenderParts)) {
                this.initAdaptive();
                this.setRate(this.props.compData.playbackSpeed || 1);
            }
            this.props.setMediaAPI(this.mediaAPI);

            //Expose the video element to media parent
            if (this.props.registerMediaSource) {
                this.props.registerMediaSource(this.refs.video);
            }
        },

        componentDidLayout() {
            this.loadSource();
        },

        componentDidUpdate() {
            if (isVideoPartRendered(this.props.videoRenderParts)) {
                this.initAdaptive();
                this.setRate(this.props.compData.playbackSpeed || 1);
                // render might have changed attributes that did not trigger any event,
                // we compensate for that.
                this.props.notifyMediaState({
                    type: consts.eventTypes.LOAD,
                    looped: this.props.compProp.loop
                });
            }
        },

        componentWillUnmount() {
            this.props.setMediaAPI(null);
            this.props.notifyVideoVisibility(false);
            if (isVideoPartRendered(this.props.videoRenderParts)) {
                this.refs.video.removeEventListener('timeupdate', this.handlePosterVisibilityOnce);
                this.removeVideoSecurely();
            }
        },

        // Internals
        loadSource() {
            const newUrl = this.props.playbackUrl;
            const format = this.props.format;
            const currentUrl = format === 'hls' && this.hls ? this.hls.url : this.refs.video.currentSrc;
            if (currentUrl !== newUrl) {
                if (format === 'hls' && this.hls) {
                    this.hls.loadSource(newUrl);
                } else {
                    this.refs.video.src = newUrl;
                    this.refs.video.load();
                }
            }
        },

        shouldInitiateAdaptive() {
            // return truthy when 1. format is hls 2. browser doesnt support hls 3. framework isnt initiated already
            return this.props.format === 'hls' &&
                this.refs.video.canPlayType('application/vnd.apple.mpegURL') !== 'maybe' &&
                !_.get(this.hls, 'media');
        },

        initAdaptive() {
            if (this.shouldInitiateAdaptive()) {
                // HLS JS framework
                //todo: find a nicer way to get or keep the Hls instance
                // load Hls module synchronous since it already loaded
                //eslint-disable-next-line
                const Hls = requirejs('hls-light');
                this.hls = new Hls(_.clone(this.props.config.hls));
                this.hls.attachMedia(this.refs.video);
                this.hls.on(Hls.Events.ERROR, this.onHlsError);
            }
        },

        /**
         * Hide the poster when video playback starts
         * NOTE: this event removes itself once it meets the conditions which invokes it
         */
        handlePosterVisibilityOnce() {
            //MSE + HLS.JS bug https://jira.wixpress.com/browse/WEED-9688 ,
            // Safari & FF sets the current time > 0 while video is paused
            if (!this.refs.video.paused && this.refs.video.currentTime > 0) {
                this.refs.video.removeEventListener('timeupdate', this.handlePosterVisibilityOnce);
                this.props.notifyMediaState({
                    type: consts.eventTypes.PLAYSTATE,
                    playbackState: consts.playbackTypes.SEEK_PLAYING
                });
                this.refs.video.addEventListener('seeked', this.setPosterState);
                this.seek(0);
            }
        },

        setPosterState() {
            this.refs.video.removeEventListener('seeked', this.setPosterState);
            if (!this.shouldMute()) {
                // reset volume listener and unmute video
                this.refs.video.muted = false;
                this.refs.video.addEventListener('volumechange', this.onVolumeChange);
            }
            this.props.notifyVideoVisibility(true);
        },

        resetPosterState() {
            if (this.props.showMedia) {
                this.props.notifyVideoVisibility(false);
                this.refs.video.addEventListener('timeupdate', this.handlePosterVisibilityOnce);
            }
        },

        /**
         * To prevent weird browser bugs, remove the video "securely" -
         * Remove every video source and reload the video object with empty sources
         */
        removeVideoSecurely() {
            this.refs.video.pause();
            if (this.hls) {
                this.hls.detachMedia();
                this.hls.destroy();
            }
            this.refs.video.src = '';
            this.refs.video.load();
        },

        /**
         * Return true if video has at least one frame
         * @returns {boolean}
         */
        canVideoPlay() {
            return this.refs.video.readyState >= this.refs.video.HAVE_CURRENT_DATA;
        },

        /**
         * Should video be muted by properties in data or props of the component
         * @returns {boolean}
         */
        shouldMute() {
            //after first play take mute state from video
            if (this.props.showMedia && this.refs.video) {
                return this.refs.video.muted;
            }
            return this.props.compProp.disableAudio ||
                _.get(this.props, ['compProp', 'mute'], _.get(this.props, ['compData', 'mute'])) ||
                this.props.compData.hasAudio === false;
        },

        // API

        // mediaAPI() moved to videoPlayerMixin.

        /**
         * Play the video
         * if video is not ready to play tag it to play when ready.
         */
        play() {
            if (!this.props.showMedia && !this.shouldMute()) {
                this.refs.video.removeEventListener('volumechange', this.onVolumeChange);
                this.mute();
            }

            if (this.canVideoPlay()) {
                this.refs.video.play();
            } else {
                this.playWhenReady = true;
            }
        },

        /**
         * Pause the video
         */
        pause() {
            this.refs.video.pause();
        },

        /**
         * Stop the video (Pause and set play head to 0)
         */
        stop() {
            this.pause();
            this.seek(0);
            this.resetPosterState();
        },

        /**
         * Set the volume
         * @param {number} volume - min: 0, max: 1
         */
        setVolume(volume) {
            this.refs.video.volume = Math.max(0, Math.min(1, volume));
        },

        /**
         * Mute the video
         */
        mute() {
            this.refs.video.muted = true;
        },

        /**
         * Un-mute the video if audio is enabled and video has audio
         */
        unMute() {
            this.refs.video.muted = this.props.compProp.disableAudio;
        },

        /**
         * Seek the video
         * @param {number} time in seconds - min: 0, max: video duration
         */
        seek(time) {
            this.refs.video.currentTime = Math.max(0, Math.min(time, this.refs.video.duration || this.props.compData.duration));
        },

        /**
         * Change the video speed
         * @param {number} speed the video speed, min: 0
         */
        setRate(speed) {
            this.refs.video.playbackRate = Math.max(0, speed);
        },

        // Event Listeners

        /**
         * On the first time the video element loads
         * Happens only once per load() video event
         */
        onLoadStart() {
            this.props.notifyMediaState({
                type: consts.eventTypes.LOAD,
                playbackState: consts.playbackTypes.READY,
                volume: this.refs.video.volume,
                muted: this.refs.video.muted,
                looped: this.props.compProp.loop,
                currentTime: this.refs.video.currentTime,
                progress: 0
            });
        },

        onDurationChange() {
            this.props.notifyMediaState({
                type: consts.eventTypes.LOAD,
                duration: parseInt(this.refs.video.duration * 100, 10) / 100
            });
        },

        /**
         * When the first frame loads, check if player should autoplay, else get into IDLE state
         * Happens only once per load() video event
         */
        onLoadedData() {
            if (!this.playWhenReady) {
                this.props.notifyMediaState({
                    type: consts.eventTypes.LOAD,
                    playbackState: consts.playbackTypes.IDLE
                });
            }
        },

        /**
         * When the video can play, check if it received a command to play when it couldn't and play
         * For autoplay, play after seek etc.
         */
        onCanPlay() {
            if (this.playWhenReady) {
                this.play();
                this.playWhenReady = false;
            }
        },

        /**
         * On every play head time update
         */
        onTimeUpdate() {
            this.props.notifyMediaState({
                type: consts.eventTypes.TIME_UPDATE,
                currentTime: this.refs.video.currentTime
            });
        },

        /**
         * when the video reaches the end of its play head and is not looped
         */
        onPlayEnded() {
            this.props.notifyMediaState({
                type: consts.eventTypes.PLAYSTATE,
                playbackState: consts.playbackTypes.PLAY_ENDED
            });
        },

        /**
         * On every time the playback changes from paused to playing
         */
        onPlay() {
            this.props.notifyMediaState({
                type: consts.eventTypes.PLAYSTATE,
                playbackState: consts.playbackTypes.PLAYING
            });
        },

        /**
         * On every time the playback changes from playing to paused
         */
        onPause() {
            this.props.notifyMediaState({
                type: consts.eventTypes.PLAYSTATE,
                playbackState: consts.playbackTypes.PAUSED
            });
        },

        /**
         * On HLS error events
         * @param {Event} event
         * @param {object} data, event data
         */
        onHlsError(event, data) {
            if (data.fatal) {
                this.refs.video.src = '';
                this.props.notifyMediaState({
                    type: consts.eventTypes.ERROR,
                    error: consts.errorTypes.NO_HLS_VIDEO
                });
            }
        },

        /**
         * On error events
         * @param {Event} event
         */
        onError(event) {
            if (event.currentTarget.networkState === event.currentTarget.NETWORK_NO_SOURCE) {
                this.refs.video.src = '';
                this.props.notifyMediaState({
                    type: consts.eventTypes.ERROR,
                    error: consts.errorTypes.NO_VIDEO_FOUND
                });
            } else {
                this.props.notifyMediaState({
                    type: consts.eventTypes.ERROR,
                    error: consts.errorTypes.VIDEO_GENERAL_ERROR
                });
            }
        },

        // handleHlsLevelSwitched(event, data) {
        //     console.log(data)
        // },


        /**
         * On every time the buffer length changes
         */
        onProgress() {
            const buffer = this.refs.video.buffered;
            this.props.notifyMediaState({
                type: consts.eventTypes.PROGRESS,
                progress: buffer && buffer.length ? buffer.end(buffer.length - 1) : 0
            });
        },

        /**
         * Notify seek started
         */
        onSeekStart() {
            this.props.notifyMediaState({
                type: consts.eventTypes.PLAYSTATE,
                playbackState: consts.playbackTypes.SEEKING
            });
        },

        /**
         * Notify seek had ended
         */
        onSeekEnd() {
            this.props.notifyMediaState({
                type: consts.eventTypes.PLAYSTATE,
                playbackState: consts.playbackTypes.SEEKING_ENDED
            });
        },

        /**
         * On every volume change
         */
        onVolumeChange() {
            this.props.notifyMediaState({
                type: consts.eventTypes.VOLUME,
                volume: this.refs.video.volume,
                muted: this.refs.video.muted
            });
        },

        /**
         * On every video speed change
         */
        onRateChange() {
            this.props.notifyMediaState({
                type: consts.eventTypes.RATE,
                playbackRate: this.refs.video.playbackRate
            });
        },

        onVideoClick(event) {
            event.stopPropagation();
        },

        // Render

        getVideo() {
            const video = {
                preload: this.props.compData.preload || 'none',
                onEnded: this.onPlayEnded,
                onError: this.onError,
                onLoadStart: this.onLoadStart,
                onLoadedData: this.onLoadedData,
                onCanPlay: this.onCanPlay,
                onDurationChange: this.onDurationChange,
                onPause: this.onPause,
                onPlay: this.onPlay,
                onProgress: this.onProgress,
                onRateChange: this.onRateChange,
                onSeeked: this.onSeekEnd,
                onSeeking: this.onSeekStart,
                onTimeUpdate: this.onTimeUpdate,
                //onVolumeChange: this.onVolumeChange,
                onClick: this.onVideoClick,
                playsInline: !this.props.playFullscreen,
                crossOrigin: 'anonymous',
                style: {
                    visibility: this.props.keepVideoHidden ? 'hidden' : '',
                    opacity: this.props.showMedia && !this.props.keepVideoHidden ? 1 : 0
                }
            };

            if (this.props.compData.alt) {
                video['aria-label'] = this.props.compData.alt;
            }

            if (this.shouldMute()) {
                video.muted = 'muted';
            }

            return video;
        },

        getRenderParts() {
            const skinParts = {
                video: null,
                poster: null
            };
            const extraProps = {
                containerWidth: this.props.posterWidth || 0,
                containerHeight: this.props.posterHeight || 0,
                shouldRenderSrc: this.props.shouldRenderSrc,
                style: this.props.style,
                imageUrlPreMeasureParams: this.props.imageUrlPreMeasureParams
            };
            _.forEach(this.props.videoRenderParts, part => {
                switch (part) {
                    case 'video':
                        skinParts.video = this.getVideo();
                        break;
                    case 'poster':
                        skinParts.poster = this.getPosterImageComp(this.props.compData.posterImageRef, this.props.showMedia, extraProps);
                        break;
                }
            });
            return skinParts;
        },

        getSkinProperties() {
            const outerStyle = {
                width: '100%'
            };

            const parts = this.getRenderParts();

            return {
                '': {
                    'data-quality': this.props.mediaQuality,
                    'data-player-type': 'html5',
                    style: outerStyle
                },
                'video': parts.video,
                'poster': parts.poster
                /*,
                itemPropName: {
                    content: this.props.compData.title || ''
                },
                itemPropDesc: {
                    content: this.props.compData.alt || 'Video'
                },
                itemPropThumb: {
                    content: ''//_.get(this.props.compData, ['posterImageRef', 'uri'])
                },
                itemPropDate: {
                    content: ''//'2018-02-20'
                }*/
            };
        }
    };
});
